import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Carousel from "better-react-carousel";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
  FiMessageSquare,
  FiCodepen,
} from "react-icons/fi";
import { CarouselItem } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HeaderTopBar from "./HeaderTopBar";
import SEO from "./SEO";
import HeaderOne from "./HeaderOne";
import Separator from "./Separator";
import SectionTitle from "./SectionTitle";
import SlpitOne from "./SlpitOne";
import SlipFour from "./SlipFour";

import Utility from "./Utility";
import RoadMap2 from "./RoadMap2";

import "react-tabs/style/react-tabs.css";

export default function Wrestlers() {
  return (
    <Carousel
      cols={1}
      rows={1}
      gap={10}
      loop={true}
      autoplay={5000}
      hideArrow={false}
      fade={true}
    >
      {/*description={
            <ul>
              <li>
                Rob Van Dam is an American professional wrestler and occasional
                actor. Van Dam is best known for his appearances with Extreme
                Championship Wrestling (ECW) in the late 1990’s, with the World
                Wrestling Federation / World Wrestling Entertainment in the
                early to mid-2000s and then with Total Nonstop Action Wrestling
                (TNA) in the early 2010s
              </li>
            </ul>
          }*/}
      <Carousel.Item>
        <SlpitOne title="Rob Van Dam" imgs="/images/FC/talent/RobVanDam.avif" />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne
          title="Andre the Giant"
          imgs="/images/FC/talent/andreTheGiant.avif"
        />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne
          title="MACHO MAN RANDY SAVAGE"
          imgs="/images/FC/talent/machoManRandySavage.avif"
        />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne
          title="Road Warrior Hawk"
          imgs="/images/FC/talent/roadWarriorHawk.avif"
        />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne
          title="Davey Boy Smith"
          imgs="/images/FC/talent/DaveyBoySmith.avif"
        />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne
          title="Dynamite Kid"
          imgs="/images/FC/talent/DynamiteKid.avif"
        />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne title="Onita" imgs="/images/FC/talent/Onita.avif" />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne title="Sabu" imgs="/images/FC/talent/Sabu.avif" />
      </Carousel.Item>

      <Carousel.Item>
        <SlpitOne
          title="Matt Cardona"
          imgs="/images/FC/talent/MattCardona.avif"
        />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne
          title="Brian Myers"
          imgs="/images/FC/talent/BrianMyers.avif"
        />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne title="Ace Austin" imgs="/images/FC/talent/AceAustin.avif" />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne
          title="Killer Kross"
          imgs="/images/FC/talent/KillerKross.avif"
        />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne title="Kurt Angle" imgs="/images/FC/talent/KurtAngle.avif" />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne title="Madusa" imgs="/images/FC/talent/Madusa.avif" />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne title="TJ Perkins" imgs="/images/FC/talent/TJPerkins.avif" />
      </Carousel.Item>
      <Carousel.Item>
        <SlpitOne title="Chris Bey" imgs="/images/FC/talent/ChrisBey.avif" />
      </Carousel.Item>
    </Carousel>
  );
}
