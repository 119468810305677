import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const SlpitOne = ({
  title,
  description,
  li1,
  li2,
  li3,
  li4,
  btntxt,
  btnhref,
  imgs,
}) => {
  return (
    <div className="rn-splite-style">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="thumbnail image-left-content">
              <img src={imgs} alt="Section Image" />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 col-12">
            <div className="split-inner">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title">{title}</h4>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{description}</p>
              </ScrollAnimation>

              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <ul className="split-list">
                  <li> {li1}</li>
                  <li>{li2}</li>
                  <li> {li3}</li>
                  <li> {li4}</li>
                </ul>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                {btntxt != "" &&
                btntxt != null &&
                btntxt != undefined &&
                btntxt != "undefined" ? (
                  <div className="view-more-button mt--35">
                    <a className="btn-default" href={btnhref}>
                      {btntxt}
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlpitOne;
