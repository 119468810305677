import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li className="has-droupdown">
        <Link to="#">Home</Link>
        {/*<ul className="submenu">
          <li>
            <Link to="/business-consulting">Business Consulting</Link>
          </li>
  </ul>*/}
      </li>

      <li>
        <Link to={{ pathname: "https://fightcapital.io/mint" }} target="_blank">
          Mint
        </Link>
      </li>
      <li>
        <Link
          to={{ pathname: "https://www.fightcapital.io/whitepaper/" }}
          target="_blank"
        >
          White Paper
        </Link>
      </li>
    </ul>
  );
};
export default Nav;
