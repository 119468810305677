import React from "react";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
  FiMessageSquare,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import { BsDiscord } from "react-icons/bs";

const HeaderTopBar = () => {
  return (
    <div className="header-top-bar">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-12 col-12">
            <div className="header-left">
              <p>
                <a href="#link">
                  Get Your Fight Capital NFT Today! <FiChevronRight />
                </a>
              </p>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-12">
            <div className="header-right">
              <div className="social-icon-wrapper">
                <ul className="social-icon social-default icon-naked">
                  <li>
                    <Link
                      to={{ pathname: "https://twitter.com/fightcapital" }}
                      target="_blank"
                    >
                      <FiTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{ pathname: "https://instagram.com/fightcapitalnft" }}
                      target="_blank"
                    >
                      <FiInstagram />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{ pathname: "https://discord.gg/fightcapital" }}
                      target="_blank"
                    >
                      <BsDiscord />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopBar;
