import logo from "./logo.svg";
import "./App.css";
// Import Css Here
import "./assets/scss/style.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Carousel from "better-react-carousel";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
  FiMessageSquare,
  FiCodepen,
} from "react-icons/fi";
import { CarouselItem } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HeaderTopBar from "./pages/HeaderTopBar";
import SEO from "./pages/SEO";
import HeaderOne from "./pages/HeaderOne";
import Separator from "./pages/Separator";
import SectionTitle from "./pages/SectionTitle";
import SlpitOne from "./pages/SlpitOne";
import SlipFour from "./pages/SlipFour";
import Wrestlers from "./pages/Wrestlers";
import Utility from "./pages/Utility";
import RoadMap2 from "./pages/RoadMap2";
import Team2 from "./pages/Team2";
import CalltoActionFive from "./pages/CalltoActionFive";
import Socials from "./pages/Socials";
import Home from "./Home";
function App() {
  return (
    <Router>
      <Switch>
        <Route
          path={`${process.env.PUBLIC_URL + "/"}`}
          exact
          component={Home}
        />
      </Switch>
    </Router>
  );
}

export default App;
